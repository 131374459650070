<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height" v-if="dashboard_data.show_debt_message">
      <b-col xl="12" md="12">
        <b-card class="card-congratulation-medal">
          <b-card-text class="font-small-3 text-danger">
            <strong style="text-transform: bold">
              <feather-icon icon="BellIcon" font-scale="3" animation="cylon" />
              Cher <b class="text-primary">{{ userName }}</b>, {{ dashboard_data.debt_message }} 
            </strong>
          </b-card-text>
          <b-button to="/transaction/recharge/recharge-add-new" variant="primary">
            Créditer le compte <!-- pour {{ dashboard_data.subscriptions }} -->
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col xl="3" md="12">
        <b-card v-if="data.congratulations" class="card-congratulation-medal">
          <!-- '+userLevel+' -->
          <b-img :src="require('@/assets/images/illustration/badge.svg')" class="congratulation-medal badgefelicitation"
            :alt="userLevel" />
          
          <h5> Félicitation 🎉 {{ data.congratulations.name }}!</h5>

          <b-card-text class="font-small-3">
            Solde du portefeuille
          </b-card-text>

          <h3 class="mb-75 mt-2 pt-50">
            <b-link style="font-size: 40px">{{ dashboard_data.wallet_balance }}$</b-link>
          </h3>
        </b-card>

      </b-col>

      <b-col xl="9" md="12">
        <b-card no-body class="card-statistics">
          <!--  <b-card-header>
            <b-card-title>
              <feather-icon size="24" icon="UsersIcon" /> Parrainages : <strong class="text-success">{{ dashboard_data.total_invite }}</strong>
              <feather-icon size="24" icon="ListIcon" /> Frais Payés : <strong class="text-success">{{ dashboard_data.total_frais_retrait }}</strong>
            </b-card-title>
            <b-card-text class="font-small-2 mr-25 mb-0">
              Mise à jour chaque matin
            </b-card-text>
          </b-card-header> -->
          <b-card-body class="statistics-body">
            <b-row>
              <b-col class="mb-3 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary" style="color: rgb(236, 0, 158)">
                      <feather-icon size="24" icon="UsersIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      Invités
                    </h4>
                    <b-card-text class="font-small-3 mb-0" style="color: rgb(236, 0, 158)">
                      {{ dashboard_data.total_invite }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>

              <b-col class="mb-3 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-warning">
                      <feather-icon size="24" icon="ListIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      Aquis
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      <span class="text-warning">{{ dashboard_data.total_transfert_received }}$</span> <span
                        style="font-size:10px">Réçus</span>
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col class="mb-3 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-danger">
                      <feather-icon size="24" icon="ListIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      Envoyé
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      <span class="text-danger">{{ dashboard_data.total_transfert }}$</span> <span
                        style="font-size:10px"></span>
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>

              

              <b-col class="mb-3 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="BoxIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      Dépots
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ dashboard_data.total_recharge_pack_actuel }}$
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              
            </b-row>

            <b-row style="margin-top:15px">

              <b-col class="mb-3 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary" style="color: rgb(39, 105, 235)">
                      <feather-icon size="24" icon="DollarSignIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      Retraits
                    </h4>
                    <b-card-text class="font-small-3 mb-0" style="color: rgb(39, 105, 235)">
                      {{ dashboard_data.total_retrait }}$
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              
              <b-col class="mb-3 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary" style="color: rgb(159, 1, 190)">
                      <feather-icon size="24" icon="DollarSignIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      Frais <span style="font-size: 12px"> Retrait</span>
                    </h4>
                    <b-card-text class="font-small-3 mb-0" style="color: rgb(159, 1, 190)">
                      {{ dashboard_data.total_frais_retrait }}$
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>

              <b-col class="mb-3 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-info">
                      <feather-icon size="24" icon="DollarSignIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      Gains
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      <span class="text-info"> {{ dashboard_data.somme_gain }}$</span>
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>

              <b-col class="mb-3 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-success">
                      <feather-icon size="24" icon="DollarSignIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      Bonus
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      <span class="text-success"> {{ dashboard_data.somme_bonus }}$</span>
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12">
        <b-row class="match-height">

          <!--  <b-col lg="4" md="6" cols="4">
            <ecommerce-order-chart :data="data.statisticsOrder" />
          </b-col>
          
          <b-col lg="4" md="6" cols="4">
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col>
          <b-col lg="4" md="6" cols="4">
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col> -->

          <!--  <b-col
            lg="12"
            md="6"
          >
            <ecommerce-earnings-chart :data="data.earningsChart" />
          </b-col> -->
        </b-row>
      </b-col>

      <!-- Revenue Report Card -->
      <!--  <b-col lg="8">
        <ecommerce-revenue-report :data="data.revenue" />
      </b-col> -->
      <!--/ Revenue Report Card -->
    </b-row>

    <b-row class="match-height">
      <b-col xl="12" md="12">
        <b-card class="card-congratulation-medal">
          <b-card-text class="font-small-3" @click="shareLink()">
            <span :href="sharedLink" @click="shareLink()"> {{ sharedLink }}</span>
          </b-card-text>
          <b-card-text class="font-small-3">
            <!-- AddToAny BEGIN -->
            <div class="a2a_kit a2a_kit_size_32 a2a_default_style" :data-a2a-url="sharedLink">
              <a class="a2a_dd" href="https://www.addtoany.com/share"></a>
              <a class="a2a_button_copy_link"></a>
              <a class="a2a_button_whatsapp"></a>
              <a class="a2a_button_facebook"></a>
              <a class="a2a_button_twitter"></a>
              <a class="a2a_button_telegram"></a>
              <a class="a2a_button_facebook_messenger"></a>
              <a class="a2a_button_google_gmail"></a>
              <a class="a2a_button_viber"></a>
              <a class="a2a_button_sms"></a>
            </div>
            <script type="application/javascript">
              var a2a_config = a2a_config || {};
                a2a_config.locale = "fr";
            </script>
            <script type="application/javascript" async src="https://static.addtoany.com/menu/page.js"></script>
            <!-- AddToAny END -->
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <!-- Company Table Card -->
      <!--  <b-col lg="8">
        <ecommerce-company-table :table-data="data.companyTable" />
      </b-col> -->
      <!--/ Company Table Card -->

      <!-- Developer Meetup Card -->
      <!-- <b-col
        lg="4"
        md="6"
      >
        <ecommerce-meetup :data="data.meetup" />
      </b-col> -->
      <!--/ Developer Meetup Card -->

      <!-- Browser States Card -->
      <!-- <b-col
        lg="4"
        md="6"
      >
        <ecommerce-browser-states />
      </b-col> -->
      <!--/ Browser States Card -->

      <!-- Goal Overview Card -->
      <!--  <b-col
        lg="4"
        md="6"
      >
        <ecommerce-goal-overview :data="data.goalOverview" />
      </b-col> -->
      <!--/ Goal Overview Card -->

      <!-- Transaction Card -->
      <!--  <b-col
        lg="4"
        md="6"
      >
        <ecommerce-transactions :data="data.transactionData" />
      </b-col> -->
      <!--/ Transaction Card -->
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BImg,
  BButton,
  BCardHeader,
  BCardTitle,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BCardBody,

} from 'bootstrap-vue'
import { kFormatter } from "@core/utils/filter";
import { getUserData } from '@/auth/utils'
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  },
  data() {
    return {
      data: {},
      dashboard_data: {},
      userLevel: "",
      sharedLink: "",
      userName: "...",
    }
  },
  directives: {
    Ripple,
  },
  mounted() {

    // load data
    const userData = JSON.parse(localStorage.getItem("userData"));
    this.sharedLink = userData.sharedLink;
    this.userLevel = userData.level;
    this.userName = userData.fullName;

    // get data for dashboard
    myApi({
      url: "dashboard-client",
      method: "get",
    })
      .then((response) => {

        this.dashboard_data = response.data.data; 

      })
      .catch((error) => {

      });

  },
  created() {
    // data
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData()
        this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      })
  },
  methods: {
    kFormatter,
    shareLink() {
      if (navigator.canShare) {
        navigator.share({
          title: this.userName + " CBP Page",
          text: "Mon lien de parrainage",
          url: this.sharedLink,
        });
      } else {
        //functionality for desktop
      }
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>

<style lang="css" scoped>
@media only screen and (min-width: 800px) {
  .badgefelicitation {
    width: 50px;
    right: 2px;
  }
}
</style>

